import React, { useEffect, useState } from "react";
import { AuthContext, UserContext } from "../context/authContext";
import firebase from "firebase/compat/app";
import { auth, getDataWithId, UserData } from "../firebaseSetup";

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userData, setUserData] = useState<UserData>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    async function fetchUser() {
      const tempUser = await getDataWithId("Users", auth.currentUser?.uid!);
      setUserData({ nickname: tempUser?.nickname, badges: tempUser?.badges });
    }
    
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      fetchUser();
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={user}>
      <UserContext.Provider value={userData!}>
      {!loading && children}
      </UserContext.Provider>
    </AuthContext.Provider>
  );
};
