import "../src/styles/index.scss";
import React, { useContext } from "react";
import { Provider } from "jotai";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HomePage } from "./components/home/homePage";
import { Dashboard } from "./components/dashboard/dashboard";
import { ForgottPasswordPage } from "./pages/forgottPasswordPage/forgottPasswordPage";
import { AuthContext } from "./context/authContext";
import { AuthProvider } from "./provider/authProvider";
import { RegisterPage } from "./pages/registerPage/registerPage";
import { LoginPage } from "./pages/loginPage/logInPage";
import { VocabularyPage } from "./pages/vocabularyPage/vocabularyPage";
import { WritingPage } from "./pages/writingPage/writingPage";
import { ReadingPage } from "./pages/readingPage/readingPage";
import { PrivateRoute } from "./components/privateRoute/privateRoute";

import { EditProfilePage } from "./pages/editProfilePage/editProfilePage";
import { SettingsPage } from "./pages/settingsPage/settingsPage";
import { ContactPage } from "./pages/contactPage/contactPage";
import { MorePage } from "./pages/morePage/morePage";
import { ListeningPage } from "./pages/listeningPage/listeningPage";
import { GrammarPage } from "./pages/grammarPage/grammarPage";
import { GamesPage } from "./pages/gamesPage/gamesPage";

export type AppProps = {};

export const App = (props: AppProps): JSX.Element => {
  const user = useContext(AuthContext);

  return (
    <Provider>
      <Router>
        <div className='app'>
          <AuthProvider>
            <Switch>
              <Route path='/' exact component={HomePage} />
              {/* <Route path='/register' component={RegisterPage} />
              <Route path='/login' component={LoginPage} />
              <Route path='/forgottPassword' component={ForgottPasswordPage} />
              <PrivateRoute path='/dashboard' component={Dashboard} />
              <PrivateRoute path='/vocabulary' component={VocabularyPage} />
              <PrivateRoute path='/write' component={WritingPage} />
              <PrivateRoute path='/read' component={ReadingPage} />
              <PrivateRoute path='/listen' component={ListeningPage} />
              <PrivateRoute path='/grammar' component={GrammarPage} />
              <PrivateRoute path='/games' component={GamesPage} />
              <PrivateRoute path='/editProfile' component={EditProfilePage} />
              <PrivateRoute path='/settings' component={SettingsPage} />
              <PrivateRoute path='/contact' component={ContactPage} />
              <PrivateRoute path='/more' component={MorePage} /> */}
            </Switch>
          </AuthProvider>
        </div>
      </Router>
    </Provider>
  );
};
