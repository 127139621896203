import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBBRvHPzY2pN722HjDPyWY3YGcgc573lFU",
  authDomain: "kanjida-auth.firebaseapp.com",
  projectId: "kanjida-auth",
  storageBucket: "kanjida-auth.appspot.com",
  messagingSenderId: "8754504796",
  appId: "1:8754504796:web:6db654cf592616fac43299",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();

export const getData = async (collection: string) => {
  try {
    const tempData = await db.collection(collection).get();
    return tempData;
  } catch (error) {
    console.log(error);
  }
};

export const getDataWithId = async (collection: string, id: string) => {
  try {
    const tempData = await db.collection(collection).doc(id).get();
    return tempData.data();
  } catch (error) {
    console.log(error);
  }
};

export const postData = (
  collection: string,
  id: string | undefined,
  object: {}
) => {
  try {
    db.collection(collection).doc(id).set(object);
  } catch (error) {
    console.log(error);
  }
};

export const getDataWhere = async (
  collection: string,
  field: string,
  operator: any,
  condition: string
) => {
  try {
    const tempData = await db
      .collection(collection)
      .where(field, operator, condition)
      .get();
    return tempData;
  } catch (error) {
    console.log(error);
  }
};

export const deleteDataWithId = (collection: string, id: string) => {
  try {
    db.collection(collection).doc(id).delete();
  } catch (error) {
    console.log(error);
  }
};

export const updateDataWithId = (
  collection: string,
  id: string,
  object: {}
) => {
  try {
    db.collection(collection).doc(id).update(object);
  } catch (error) {
    console.log(error);
  }
};

export type UserData = {
  nickname: string;
  badges: boolean[]
};

export const defaultUserData: UserData = {
  nickname: "Schüler",
  badges: [
    false, false, false, false
  ]
};

export default firebase;
