import React, { useRef, useEffect, useState } from "react";
import { HomeSection } from "./sectionComponent/homeSection";
import { Header } from "../shared/headerComponent/header";
import { HeroElement } from "../heroElement/heroElement";
import { ScrollIndicator } from "../scrollIndicator/scrollIndicator";
import firebase from "../../firebaseSetup";
import { descriptions } from "./descriptions";
import { Viewport } from "../viewport/viewport";
import { TestScene } from "../../threeComponents/testScene";

export type HomeProps = {};

export const HomePage = (props: HomeProps): JSX.Element => {
  const [vocab, setVocab] = useState<
    { de: string; jap: string; level: string }[]
  >([]);
  const [reveal, setReveal] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = firebase.firestore().collection("Vocabulary");


  return (
    <div className='container'>
      {/* <Header /> */}
      {/* <HeroElement /> */}
      {/* <HomeSection
        id='vokabeln'
        indexClass='one'
        section='Vokabeln'
        description={<p>{descriptions.vocabulary.desc_1}</p>}
        description2={<p>{descriptions.vocabulary.desc_2}</p>}
        description3={<p>{descriptions.vocabulary.desc_3}</p>}
        link='vocabulary'
        buttonLabel='Vokabeln lernen'
      /> */}
      {/* <Viewport sceneID='mainTestScene' >
        <TestScene />
      </Viewport> */}
      {/* <HomeSection
        id='schreiben'
        indexClass='two'
        section='Schreiben'
        description={<p>{descriptions.writing.desc_1}</p>}
        description2={<p>{descriptions.writing.desc_2}</p>}
        description3={<p>{descriptions.writing.desc_3}</p>}
        link='write'
        buttonLabel='Kana und kanji lernen'
      />
      <HomeSection
        id='lesen'
        indexClass='three'
        section='Lesen'
        description={<p>{descriptions.reading.desc_1}</p>}
        description2={<p>{descriptions.reading.desc_2}</p>}
        description3={<p>{descriptions.reading.desc_3}</p>}
        link='read'
        buttonLabel='Lesen üben'
      />
      <HomeSection
        id='hoeren'
        indexClass='four'
        section='Hören'
        description={<p>{descriptions.listening.desc_1}</p>}
        description2={<p>{descriptions.listening.desc_2}</p>}
        description3={<p>{descriptions.listening.desc_3}</p>}
        link='listen'
        buttonLabel='Hörverständnis üben'
      />
      <HomeSection
        id='grammatik'
        indexClass='five'
        section='Grammatik'
        description={<p>{descriptions.grammar.desc_1}</p>}
        description2={<p>{descriptions.grammar.desc_2}</p>}
        description3={<p>{descriptions.grammar.desc_3}</p>}
        link='grammar'
        buttonLabel='Grammatik lernen'
      />
      <HomeSection
        id='spiele'
        indexClass='five'
        section='Spiele'
        description={<p>{descriptions.games.desc_1}</p>}
        description2={<p>{descriptions.games.desc_2}</p>}
        description3={<p>{descriptions.games.desc_3}</p>}
        link='games'
        buttonLabel='zu den Spielen'
      /> */}
      <div className='cot-container'>
        <h1>Bald ist es so weit!</h1>
        <h3>
          Lerne kostenlos japanisch mit unterhaltsamen Übungen und neuen
          Konzepten.
        </h3>
        <p>Verpasse nicht den Start.</p>
        <p>Schaue in ein paar Wochen wieder vorbei.</p>
      </div>
      {/* <ScrollIndicator /> */}
    </div>
  );
};
